import {
  MIDIAccess,
  MIDIConnectionEvent,
  MIDIInput,
  MIDIMessageEvent,
  requestMIDIAccess,
} from "@motiz88/react-native-midi";
import { useCallback, useEffect, useRef, useState } from "react";

const noteNames = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
] as const;

const COMMANDS = {
  noteOn: 0x09,
  noteOff: 0x08,
} as const;

export const parseMidiEvent = (data: Uint8Array) => {
  if (data.length === 3) {
    const status = data[0];
    const command = status >>> 4;

    if (command === COMMANDS.noteOn || command === COMMANDS.noteOff) {
      const note = data[1];
      const velocity = data[2];

      const octave = Math.trunc(note / 12);
      const noteName = noteNames[note % 12];
      return {
        command: command === COMMANDS.noteOn ? "start" : "end",
        note: `${noteName}${octave}`,
        noteName,
        octave,
        velocity,
      } as const;
    }
  }
  return null;
};

const excludedDevices = [] as string[];

export const useMidiAccess = () => {
  const ref = useRef<MIDIAccess>();
  const [ready, setReady] = useState(false);

  if (!ref.current) {
    try {
      requestMIDIAccess().then((midiAccess) => {
        ref.current = midiAccess;

        setReady(true);
      });
    } catch (ex) {
      console.log("catched exception", ex);
    }
  }
  return ref.current;
};

export const useMidiMessageListener = ({
  listener,
}: {
  listener: (e: MIDIMessageEvent) => void;
}) => {
  const midiAccess = useMidiAccess();
  const [devices, setDevices] = useState<MIDIInput[]>([]);

  useEffect(() => {
    if (midiAccess) {
      setDevices(Array.from(midiAccess.inputs.values()));
    }
  }, [midiAccess?.inputs.size]);

  console.log("devices", devices.length, devices);

  const onStateChange = useCallback((event: MIDIConnectionEvent) => {
    // doesn't seem to ever be called on web ¯\_(ツ)_/¯
    if (midiAccess) {
      setDevices(Array.from(midiAccess.inputs.values()));
    }
  }, []);

  useEffect(() => {
    if (midiAccess) {
      midiAccess?.addEventListener("statechange", onStateChange);
      return midiAccess?.removeEventListener(
        "statechange",
        onStateChange as never
      );
    }
  }, [midiAccess, onStateChange]);

  useEffect(() => {
    devices.forEach((device) => {
      console.log("adding listener to device", device.name);
      device.addEventListener("midimessage", listener);
    });
    return () => {
      devices.forEach((device) => {
        device.removeEventListener("midimessage", listener as never);
      });
    };
  }, [listener, midiAccess, devices]);
};
