import { MIDIMessageEvent } from "@motiz88/react-native-midi";
import { RouteProp, useRoute } from "@react-navigation/native";
import tonalNote from "@tonaljs/note";
import { StatusBar } from "expo-status-bar";
import { useCallback, useRef, useState } from "react";
import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { RootStackParamList } from "../../App";
import { Keyboard } from "../../keyboard/Keyboard";
import { parseMidiEvent, useMidiMessageListener } from "../../midi";
import { detectChord } from "../../notes/chords";
import { getNoteData, Note } from "../../notes/note";


const pickRandom = <T,>(things: T[], avoid: T[] = []): T => {
  const possibles = things.filter((element) => !avoid.includes(element));

  return possibles[Math.floor(Math.random() * possibles.length)];
};

const getMidi = (note: string) => tonalNote.get(note).midi!;
const midiComparator = (a: string, b: string) => getMidi(a) - getMidi(b);

const useGuess = ({
  chord,
  onSuccess,
  chordsToGuess
}: {
  chord: string;
  onSuccess?: () => void;
  chordsToGuess: string[];
}) => {
  const [target, setTarget] = useState(() => pickRandom(chordsToGuess, []));
  const next = useRef<string | null>(null);

  if (chord == target) {
    if (next.current === null) {
      next.current = pickRandom(chordsToGuess, [chord]);
      setTimeout(() => {
        console.log("TIMEOUT");
        setTarget(next.current!);
        next.current = null;
        onSuccess && onSuccess();
      }, 1000);
    }
    return { next, status: "success", target } as const;
  }
  if (!chord) {
    return { next, status: "idle", target } as const;
  }
  return {
    next,
    status: "error",
    target,
  } as const;
};

const TargetInfo: React.FC<{
  target: string;
  status: "idle" | "success" | "error";
}> = ({ target, status }) => {
  return (
    <View style={{ alignItems: "center", padding: 32 }}>
      <Text style={{ fontSize: 32 }}>Chord to play: {target}</Text>
      <Text style={{ fontWeight: "bold", fontSize: 64, color: "green" }}>
        {status == "success" ? "Correct!" : " "}
      </Text>
    </View>
  );
};

export const ChordsTrainingScreen: React.FC = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'chord-training'>>();

  const chordsToGuess = route.params.chords

  console.log({ chordsToGuess })

  const [notes, setNotes] = useState<Partial<Record<Note, boolean>>>({});

  const pressedKeys = Object.entries(notes)
    .filter(([_, pressed]) => pressed)
    .map(([note]) => note as Note)
    .sort(midiComparator);

  const chord = detectChord(pressedKeys);

  console.log({ pressedKeys, chord });

  const listener = useCallback((event: MIDIMessageEvent) => {
    console.log("CALLBACK", "received at", event.receivedTime);
    const note = parseMidiEvent(event.data);
    setNotes((notes) => {
      if (note) {
        return {
          ...notes,
          [note.note]: note.command === "start",
        };
      } else {
        return notes;
      }
    });
  }, []);

  useMidiMessageListener({ listener });

  const guess = useGuess({
    chord,
    onSuccess: () => {
      setNotes({});
    },
    chordsToGuess
  });

  console.log({ guess });

  return (
    <SafeAreaView style={styles.container}>
      <StatusBar style="auto" />
      <View>
        <TargetInfo target={guess.target} status={guess.status} />
        {/* <Text>{JSON.stringify(guess, null, 2)}</Text> */}
      </View>
      <View style={{ flex: 1, justifyContent: "center" }}>
        <Text style={{ fontWeight: "bold", fontSize: 32 }}>
          {" "}
          Current pressed chord: {chord ?? "none"}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 32,
          }}
        >
          {pressedKeys.map((noteName) => (
            <Text
              key={noteName}
              style={{ fontSize: 32, marginLeft: 24, marginRight: 24 }}
            >
              {getNoteData(noteName).pc}
              <Text style={{ fontSize: 16 }}>{getNoteData(noteName).oct}</Text>
            </Text>
          ))}
        </View>
      </View>
      <Keyboard
        pressedNotes={notes}
        startNote={12}
        endNote={21 + 12 * 4}
        onNote={({ midi, note, value }) => {
          console.log({ midi, note, value });
          setNotes((notes) => {
            return {
              ...notes,
              [note]: !notes[note],
            };
          });
        }}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
