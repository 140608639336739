import { LinkingOptions, NavigationContainer, useNavigation } from "@react-navigation/native";
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { StatusBar } from "expo-status-bar";
import { Button, Heading, NativeBaseProvider, VStack } from "native-base";
import * as React from "react";
import { View } from "react-native";
import { ChordsSelectionScreen } from "./features/chords-training/ChordsSelectionScreen";
import { ChordsTrainingScreen } from "./features/chords-training/ChordsTrainingScreen";

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  enabled: true,
  prefixes: ['http://localhost:19006',],

};

const RootStack = createNativeStackNavigator<RootStackParamList>();

export type RootStackParamList = {
  Menu: undefined;
  "chord-training/chords-selection": undefined;
  "chord-training": { chords: string[] };
};


export type NavigatorProp = NativeStackNavigationProp<RootStackParamList>

export default function App() {
  return (
    <NativeBaseProvider>
      <NavigationContainer linking={linking}>
        <StatusBar style="auto" />
        <RootStack.Navigator initialRouteName="Menu">
          <RootStack.Screen name="Menu" component={MainMenu} navigationKey="menu" />
          <RootStack.Screen
            name="chord-training/chords-selection"
            component={ChordsSelectionScreen}
          />
          <RootStack.Screen
            name="chord-training"
            component={ChordsTrainingScreen}
          />
        </RootStack.Navigator>
      </NavigationContainer>
    </NativeBaseProvider>
  );
}


const MainMenu = () => {
  const navigation = useNavigation<NavigatorProp>();
  return (
    <VStack
      w="100%"
      flex={1}
      maxW="600"
      marginX={"auto"}
      alignItems="center"
      space={4}
    >
      <Heading> unnamed piano helper app</Heading>

      <View style={{ flex: 1 }} />

      <Button onPress={() => navigation.navigate("chord-training/chords-selection")}>
        Chords Trainer
      </Button>

      <View style={{ flex: 2 }} />
    </VStack>
  );
};
