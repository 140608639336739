import * as tonalNote from "@tonaljs/note";

export type NoteName =
  | "C"
  | "C#"
  | "D"
  | "D#"
  | "E"
  | "F"
  | "F#"
  | "G"
  | "G#"
  | "A"
  | "A#"
  | "B";

export const notenames = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
] satisfies NoteName[];

const octaves = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;
type Octave = typeof octaves[number];

export type Note = `${NoteName}${Octave}`;

export const getNoteData = (note: Note) => {
  return tonalNote.get(note);
};
