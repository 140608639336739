import * as TonalChord from "@tonaljs/chord";
import { detect } from "@tonaljs/chord-detect";
import { NoteName } from "tonal";
import { notenames } from "./note";

export type Chord = string; // TMP

export const detectChord = (keys: string[]) => {
  const chords = detect(keys, { assumePerfectFifth: true });
  return chords[0];
};

const chordsTypes = [
  "major",
  "minor",
  //
  "fifth",
  "suspended fourth",
  "suspended fourth seventh",
  "augmented",
  "major seventh flat sixth",
  "augmented seventh",
  "major seventh",
  "dominant seventh",
  "sixth",
  "major seventh sharp eleventh",
  "lydian dominant seventh",
  "minor augmented",
  "minor/major seventh",
  "minor seventh",
  "minor sixth",
  "diminished",
  "half-diminished",
  "diminished seventh",
  "dominant sharp ninth",
  "suspended second",
  "eleventh",
  "major ninth",
  "dominant ninth",
  "sixth/ninth",
  "major thirteenth",
  "dominant thirteenth",
  "major sharp eleventh (lydian)",
  "minor/major ninth",
  "minor ninth",
  "minor thirteenth",
  "minor eleventh",
  "suspended fourth flat ninth",
  "altered",
  "dominant flat ninth",
];

export const allChords = Object.fromEntries(
  notenames.map((baseNote) => {
    return [
      baseNote,
      chordsTypes.map((type) => {
        return TonalChord.getChord(type, baseNote).symbol;
      }),
    ];
  })
) as Record<NoteName, Chord[]>;
