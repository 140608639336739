import { Pressable, ScrollView, StyleSheet, Text, View } from "react-native";
import tonalNote from "@tonaljs/note";
import { Note } from "../notes/note";

export type KeyboardProps = {
  pressedNotes: Partial<Record<Note, boolean>>;
  startNote: number;
  endNote: number;
  onNote: (event: { midi: number; note: Note; value: "start" | "end" }) => void;
};

export const Keyboard: React.FC<KeyboardProps> = ({
  startNote,
  endNote,
  onNote,
  pressedNotes,
}) => {
  const keysRange = Array.from(
    { length: endNote - startNote + 1 },
    (_, i) => startNote + i
  );

  return (
    <ScrollView style={styles.keyboard} horizontal>
      {keysRange.map((number) => {
        const note = tonalNote.get(tonalNote.fromMidiSharps(number));

        return (
          <View
            style={[
              styles.keyContainer,
              note.acc ? styles.blackKeyContainer : styles.whiteKeyContainer,
            ]}
            key={number}
          >
            <Pressable
              onPress={() => {
                onNote({
                  midi: number,
                  note: `${note.name}` as Note,
                  value: "start",
                });
              }}
              style={[
                styles.key,
                note.acc ? styles.blackKey : styles.whiteKey,
                pressedNotes[note.name as Note]
                  ? { borderWidth: 12, borderColor: "blue" }
                  : null,
              ]}
            >
              <Text
                style={{
                  color: note.acc ? "white" : "black",
                }}
              >
                {note.letter}
                {note.acc}
              </Text>
            </Pressable>
          </View>
        );
      })}
    </ScrollView>
  );
};

const WHITE_KEY_WIDTH = 100;
const BLACK_KEY_WIDTH = (WHITE_KEY_WIDTH * 60) / 100;

const styles = StyleSheet.create({
  keyboard: {
    height: 90,
    width: "100%",
    borderColor: "gray",
    borderWidth: 1,
    display: "flex",
    flexDirection: "row",
    overflow: "scroll",
    backgroundColor: "yellow",
  },
  keyContainer: {
    height: "100%",
  },
  key: {
    height: "100%",
    borderColor: "red",
    borderWidth: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    paddingBottom: 12,
  },
  whiteKeyContainer: {},
  whiteKey: {
    width: WHITE_KEY_WIDTH,
    backgroundColor: "white",
  },
  blackKeyContainer: {
    zIndex: 100,
    width: 0,
    position: "relative",
  },
  blackKey: {
    color: "white",
    position: "relative",
    width: BLACK_KEY_WIDTH,
    left: -BLACK_KEY_WIDTH / 2,

    marginRight: -BLACK_KEY_WIDTH,
    backgroundColor: "black",
    height: "60%",
  },
});
