import { useNavigation } from "@react-navigation/native";
import { Box, Button, Column, Row, ScrollView, Text } from "native-base";
import React, { useCallback, useMemo, useState } from "react";
import { TouchableOpacity } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { NavigatorProp } from "../../App";
import { allChords, Chord } from "../../notes/chords";
import { NoteName } from "../../notes/note";

export const ChordsSelectionScreen: React.FC = () => {
  const navigation = useNavigation<NavigatorProp>();

  const [selectedChords, setSelectedChords] = useState<Record<Chord, boolean>>({
    CM: true,
    FM: true,
  });

  const selectedChordsArray = useMemo(() => {
    return Object.entries(selectedChords)
      .filter(([_chord, value]) => value)
      .map(([chord]) => chord);
  }, [selectedChords]);

  const onChordPress = useCallback((chord: Chord) => {
    setSelectedChords((oldState) => ({
      ...oldState,
      [chord]: !(oldState[chord] || false),
    }));
  }, []);

  const chords: Record<NoteName, Chord[]> = useMemo(() => {
    return allChords;
  }, []);

  console.log("===SCREEN RENDER===");

  return (
    <Box flex={1}>
      <ScrollView flex={1}>
        <Column>
          {Object.entries(chords).map(([baseNote, variants]) => {
            return (
              <ChordsRow
                key={baseNote}
                note={baseNote}
                variants={variants}
                selectedChords={selectedChords}
                onChordPress={onChordPress}
              />
            );
          })}
        </Column>
      </ScrollView>
      <Box bgColor="gray.300" pb={3}>
        <SafeAreaView edges={["bottom"]}>
          <Column space={4} py={2} alignItems="center">
            <Text>Selected chords:</Text>
            <Text>{selectedChordsArray.join(",")}</Text>
            <Button
              onPress={() =>
                navigation.navigate("chord-training", {
                  chords: selectedChordsArray,
                })
              }
            >
              Train!
            </Button>
          </Column>
        </SafeAreaView>
      </Box>
    </Box>
  );
};

const ChordsRow: React.FC<{
  note: string;
  variants: string[];
  selectedChords: Record<string, boolean>;
  onChordPress: (chord: Chord) => void;
}> = ({ note, variants, selectedChords, onChordPress }) => {
  console.log("=== ChordsRow RENDER===");

  const selectedCount = useMemo(() => {
    return variants
      .map((chord) => (selectedChords[chord] ? 1 : 0))
      .reduce<number>((a, b) => a + b, 0);
  }, [selectedChords, variants]);

  return (
    <Row key={note} overflowY="hidden">
      <Box
        minWidth={16}
        borderRightColor={"gray.500"}
        borderRightWidth={1}
        borderBottomColor={"gray.400"}
        borderBottomWidth={1}
        bgColor="gray.300"
      >
        {/* double box to align textx on web version because of scrollbars */}
        <Box alignItems="center" justifyContent="center" height={16}>
          <SelectedCountBadge
            count={selectedCount}
            position="absolute"
            top={1}
            right={1}
          />
          {note}
        </Box>
      </Box>
      <ScrollView horizontal>
        {variants.map((chord) => {
          return (
            <ChordCheckbox
              key={chord}
              chord={chord}
              checked={selectedChords[chord] || false}
              onPress={onChordPress}
            />
          );
        })}
      </ScrollView>
    </Row>
  );
};

const SelectedCountBadge: React.FC<
  {
    count: number;
  } & React.ComponentProps<typeof Box>
> = ({ count, ...boxProps }) => {
  if (count == 0) {
    return null;
  }
  return (
    <Box
      {...boxProps}
      backgroundColor="red.300"
      minW={4}
      height={4}
      borderRadius={9999}
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="xs">{count}</Text>
    </Box>
  );
};

const ChordCheckbox: React.FC<{
  chord: Chord;
  checked: boolean;
  onPress: (chord: Chord) => void;
}> = React.memo(function ChordCheckBox({ chord, onPress, checked = true }) {
  console.log("ChordCheckbox.render");
  return (
    <TouchableOpacity onPress={() => onPress(chord)}>
      <Box
        height={16}
        minWidth={16}
        alignItems="center"
        justifyContent="center"
        bgColor={checked ? "gray.400" : "gray.100"}
      >
        {chord}
      </Box>
    </TouchableOpacity>
  );
});
